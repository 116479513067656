.social-links {
	overflow: hidden;

	li {
		float: left;
		width: 35px;
		height: 35px;
		margin-right: 1px;

		a {
			background-color: lighten($theme-primary-color, 10%);
			width: 35px;
			height: 35px;
			line-height: 35px;
			display: block;
			color: $white;
			text-align: center;

			&:hover {
				background-color: $theme-primary-color;
			}
		}
	}


}

// header-search-form

.header-search-form {
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 99999;
	margin-top: -540px;
	transform: translateY(-100%);
	background-color: rgba(0, 0, 0, 0.90);
	-webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	-o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
	transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

	.close-header-search {
		position: absolute;
		right: 25px;
		left: auto;
		color: #ffffff;
		width: auto;
		height: auto;
		top: 25px;
		margin: 0px;
		border: none;
		-webkit-transition: all 500ms ease;
		-moz-transition: all 500ms ease;
		-ms-transition: all 500ms ease;
		-o-transition: all 500ms ease;
		transition: all 500ms ease;
		width: 50px;
		height: 50px;
		background: $theme-primary-color;
		opacity: 0;
		visibility: hidden;
		border-radius: 50%;

		i {
			position: relative;
			display: block;
			color: #ffffff;
			font-size: 20px;
		}
	}


	form {
		position: absolute;
		max-width: 700px;
		top: 50%;
		left: 15px;
		right: 15px;
		margin: -35px auto 0;
		transform: scaleX(0);
		transform-origin: center;
		background-color: transparent;
		-webkit-transition: all 300ms ease;
		-moz-transition: all 300ms ease;
		-ms-transition: all 300ms ease;
		-o-transition: all 300ms ease;
		transition: all 300ms ease;

	}


	.form-group {
		position: relative;
		margin: 0px;
		overflow: hidden;
		border-radius: 40px;
	}

	.form-group input[type="text"],
	.form-group input[type="search"] {
		position: relative;
		display: block;
		font-size: 18px;
		line-height: 50px;
		color: #000000;
		height: 70px;
		width: 100%;
		padding: 10px 30px;
		background: #ffffff;
		-webkit-transition: all 500ms ease;
		-moz-transition: all 500ms ease;
		-ms-transition: all 500ms ease;
		-o-transition: all 500ms ease;
		transition: all 500ms ease;
		font-weight: 500;
		text-transform: capitalize;
		border-radius: 40px;
		border: 0;

		&:focus {
			outline: none;
			border-color: $theme-primary-color;
		}
	}

	.form-group input[type="submit"] {
		position: absolute;
		right: 30px;
		top: 0px;
		height: 70px;
		line-height: 70px;
		background: transparent;
		text-align: center;
		font-size: 24px;
		color: #000000;
		padding: 0;
		cursor: pointer;
		-webkit-transition: all 500ms ease;
		-moz-transition: all 500ms ease;
		-ms-transition: all 500ms ease;
		-o-transition: all 500ms ease;
		transition: all 500ms ease;
	}

	.form-group input[type="submit"]:hover,
	.form-group button:hover {
		color: #000000;
	}

	.form-group {
		.search-btn {
			width: 60px;
			height: 70px;
			background: $theme-primary-color;
			color: $white;
			position: absolute;
			right: 0;
			top: 0;
			border: 0;
		}
	}

	input::placeholder,
	textarea::placeholder {
		color: #000000;
	}


	&.active {
		transform: translateY(0%);
		margin-top: 0;

		.close-header-search {
			visibility: visible;
			opacity: 1;
			-webkit-transition-delay: 1500ms;
			-moz-transition-delay: 1500ms;
			-ms-transition-delay: 1500ms;
			-o-transition-delay: 1500ms;
			transition-delay: 1500ms;
		}

		form {
			transform: scaleX(1);
			-webkit-transition-delay: 1200ms;
			-moz-transition-delay: 1200ms;
			-ms-transition-delay: 1200ms;
			-o-transition-delay: 1200ms;
			transition-delay: 1200ms;
		}

	}

}
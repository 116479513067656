.btn-style-1,
.btn-style-2 {
	background: $theme-primary-color;
	color: $white;
	display: inline-block;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 29px;
	padding: 12px 35px;
	padding-right: 60px;
	border: 0;
	z-index: 1;
	border: 2px solid $theme-primary-color;
	border-radius: 0px;
	position: relative;
	overflow: hidden;
	transition: all .8s;

	&:before {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 10px;
		width: 10px;
		z-index: -1;
		content: "";
		border-radius: 0px;
		background: $white;
		transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
	}

	&:after {
		position: absolute;
		right: 30px;
		top: 13px;
		content:"\e912";
		font-family: 'icomoon' !important;
		transform: rotate(-45deg);
		transition: all .3s;
	}

	&.btn-style-1-s3 {
		width: 100%;
		height: 100%;
		background: $white;
		color: $theme-primary-color;

		&:after {
			border: 1px solid $white;
		}
	}

	&.btn-style-1-s4 {
		background: $theme-primary-color;
		color: $white;

		&:after {
			border: 1px solid $theme-primary-color;
		}
	}

	&:hover,
	&:focus,
	&:active {
		color: $theme-primary-color;
		&:before {
			width: 100%;
			height: 100%;
			left: 0%;
			right: auto;
		}

		&:after {
			transform: rotate(0deg);
		}
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 10px 18px;
		padding-right: 40px;
		font-size: 14px;

		&:after {
			right: 16px;
			top: 12px;
		}
	}
}

.btn-style-2 {
	background: $dark-gray;
	border-color: $dark-gray;
}
/*--------------------------------------------------------------
04. Hero
--------------------------------------------------------------*/

/*1.2.1 static-hero */

.static-hero {
    position: relative;
    z-index: 1;
    padding-bottom: 120px;

    .back-shape {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 70%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media (max-width: 991px) {
        padding: 80px 30px;
    }

    @media (max-width: 575px) {
        padding: 70px 0px;
    }

    .static-banner-sec {
        position: relative;
        z-index: 1;

        img {
            @media (max-width: 575px) {
                min-height: 200px;
            }
        }

        .hero-img {
            position: absolute;
            right: 15px;
            bottom: 0;

            @media (max-width: 1199px) {
                max-width: 485px;
            }

            @media (max-width: 991px) {
                max-width: 50%;
            }

            @media (max-width: 400px) {
                max-width: 70%;
                right: -70px;
            }

            img {
                @media (max-width: 575px) {
                    min-height: unset;
                }
            }
        }

        .radial-bar {
            position: absolute;
            bottom: -115px;
            right: 50px;
            z-index: -1;

            @media (max-width: 991px) {
                max-width: 200px;
                bottom: -30px;
                right: -30px;
            }

            @media (max-width: 575px) {
                display: none;
            }

        }

    }

    .static-hero-text {
        display: flex;
        padding-top: 60px;

        @media (max-width: 991px) {
            text-align: center;
            padding-top: 0;
            display: block;
            margin-bottom: 50px;
        }

        h2 {
            font-size: 100px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            color: $dark-gray;
            margin-bottom: 30px;
            position: relative;
            display: inline-block;
            max-width: 1000px;

            @media (max-width: 1500px) {
                font-size: 100px;
                line-height: 100px;
            }

            @media (max-width: 1199px) {
                font-size: 70px;
                line-height: 70px;
                max-width: 715px;
            }

            @media(max-width:767px) {
                font-size: 50px;
                line-height: 60px;
            }

            @media (max-width: 575px) {
                font-size: 44px;
                line-height: 44px;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            @media(max-width:450px) {
                font-size: 30px;
                line-height: 35px;
            }

            span {
                color: $theme-primary-color;

                i {
                    display: block;
                    line-height: 20px;

                    img {
                        @media(max-width:991px) {
                            max-width: 45%;
                        }
                    }
                }
            }

            small {
                position: absolute;
                right: -20px;
                top: -65px;

                @media(max-width:450px) {
                    display: none;
                }
            }
        }

        .rotate-text-wrap {
            position: absolute;
            right: 200px;
            top: 100px;
            width: 200px;
            height: 200px;
            line-height: 200px;
            border-radius: 50%;
            z-index: 11;
            border: 1px dashed #707070;

            @media (max-width: 1500px) {
                right: 100px;
            }

            @media (max-width: 1400px) {
                right: 40px;
            }

            @media (max-width: 1199px) {
                top: 50px;
            }

            @media (max-width: 991px) {
                position: relative;
                left: 0;
                margin: 0 auto;
                top: 0;
            }

            .rotate-text {
				position: relative;
				top: 0px;
				animation: circle 30s linear infinite;
				max-width: 200px;

				svg {
					path {
						fill: transparent;
					}
				}
				

				text {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					color: $dark-gray;
					text-transform: uppercase;
					font-family: $heading-font;
					letter-spacing: 9px;
				}

			}

            .icon {
                position: absolute;
                left: 20%;
                top: 15px;

                i {
                    display: inline-block;
                    font-size: 50px;
                    color: $white;
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;
                    background: $theme-primary-color;
                    border-radius: 50%;
                }
            }

        }

    }

    .progress-wrap {
        border-radius: 5px;
        background: $white;
        box-shadow: 0px 4px 20px 0px rgba(191, 191, 191, 0.25);
        text-align: center;
        padding: 20px;
        max-width: 142px;
        position: absolute;
        left: -80px;
        top: 50px;

        @media (max-width: 1500px) {
            left: -50px;
        }

        @media (max-width: 767px) {
            left: 20px;
            top: 30px;
        }

        span {
            font-family: $heading-font;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            color: $dark-gray;
            display: block;
            margin-bottom: 10px;
        }

        .progress {
            width: 80px;
            height: 80px;
            line-height: 80px;
            background: none;
            margin: 0 auto;
            box-shadow: none;
            position: relative;
        }

        .progress:after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 6px solid #fff;
            position: absolute;
            top: 0;
            left: 0;
        }

        .progress>span {
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
        }

        .progress .progress-left {
            left: 0;
        }

        .progress .progress-bar {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 6px;
            border-style: solid;
            position: absolute;
            top: 0;
        }

        .progress .progress-left .progress-bar {
            left: 100%;
            border-top-right-radius: 80px;
            border-bottom-right-radius: 80px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
        }

        .progress .progress-right {
            right: 0;
        }

        .progress .progress-right .progress-bar {
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards;
        }

        .progress .progress-value {
            width: 90%;
            height: 90%;
            border-radius: 50%;
            background: #ffffff;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            color: $dark-gray;
            line-height: 65px;
            text-align: center;
            position: absolute;
            top: 5%;
            left: 5%;
            font-family: $heading-font;
        }

        .progress.blue .progress-bar {
            border-color: $theme-primary-color;
        }

        .progress.blue .progress-left .progress-bar {
            animation: loading-2 1.5s linear forwards 1.8s;
        }


        @keyframes loading-1 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }

        @keyframes loading-2 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(144deg);
                transform: rotate(144deg);
            }
        }

        @keyframes loading-3 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        @keyframes loading-4 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(36deg);
                transform: rotate(36deg);
            }
        }

        @keyframes loading-5 {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(126deg);
                transform: rotate(126deg);
            }
        }
    }



    .vector-shape {
        position: absolute;
        right: 10%;
        bottom: 20%;
        animation: zoom-in-zoom-out 5s ease infinite;
    }


}


/*3.1 hero-slider*/

.hero-slider {
    width: 100%;
    height: 900px;
    display: flex;
    position: relative;
    z-index: 0;

    @include media-query(991px) {
        height: 700px;
    }

    @include media-query(767px) {
        height: 600px;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-container,
    .hero-container {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .slide-inner {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, #1F212D 29.03%, rgba(31, 33, 45, 0.00) 100%);
            content: "";
        }

        .left-shape-1 {
            position: absolute;
            left: 0;
            top: 30px;
            z-index: 1;
        }

        .left-shape-2 {
            position: absolute;
            left: 0;
            top: 70%;
            transform: translateY(-50%);
            z-index: 1;
        }

        .frame {
            position: absolute;
            right: 0;
            bottom: 0px;
            z-index: 1;

            @media(max-width:1399px) {
                max-width: 350px;
            }
            @media(max-width:1199px) {
                max-width: 300px;
            }
            @media(max-width:991px) {
                display: none;
            }
        }    

        .slide-content {
            padding-top: 95px;
            position: relative;
            z-index: 11;

            @media(max-width:991px) {
                padding-top: 0;
            }
        }
    }

    // slider controls
    .swiper-button-prev,
    .swiper-button-next {
        background: transparentize($theme-primary-color, 0.9);
        width: 50px;
        height: 50px;
        line-height: 48px;
        border: 2px solid $theme-primary-color;
        border-radius: 50%;
        text-align: center;
        @include transition-time(0.3s);
        opacity: 0;
        visibility: hidden;
        z-index: 99;

        &:hover {
            opacity: 0.9;
        }

        @include media-query(767px) {
            display: none;
        }
    }

    .swiper-button-prev {
        left: -25px;

        &:after {
            font-family: 'icomoon';
            content: "\e913";
            font-size: 20px;
            color: $white;
        }
    }

    .swiper-button-next {
        right: -25px;

        &:after {
            font-family: 'icomoon';
            content: "\e912";
            font-size: 20px;
            color: $white;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        @include media-query(991px) {
            display: none;
        }
    }

    .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 12px;
        font-size: 12px;
        color: #000;
        opacity: 1;
        background: rgba(255, 255, 255, 0.2);

        @include widther(992px) {
            display: none;
        }
    }

    .swiper-pagination-bullet-active {
        color: #fff;
        background: $white;
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 12px;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 30px;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 8px;
    }

    &:hover {

        .swiper-button-prev,
        .swiper-button-next {
            opacity: 1;
            visibility: visible;
        }

        .swiper-button-prev {
            left: 25px;
        }

        .swiper-button-next {
            right: 25px;
        }
    }

    .slide-content {
        @media (max-width: 991px) {
            text-align: center;
            padding-top: 0;
            display: block;
            margin-bottom: 50px;
        }

        h2 {
            font-size: 100px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            color: $white;
            margin-bottom: 30px;
            position: relative;
            display: inline-block;
            max-width: 1000px;
            padding-bottom: 30px;

            @media (max-width: 1500px) {
                font-size: 100px;
                line-height: 100px;
            }

            @media (max-width: 1199px) {
                font-size: 70px;
                line-height: 70px;
                max-width: 715px;
            }

            @media(max-width:767px) {
                font-size: 50px;
                line-height: 60px;
            }

            @media (max-width: 575px) {
                font-size: 44px;
                line-height: 44px;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            @media(max-width:450px) {
                font-size: 30px;
                line-height: 35px;
            }

            span {
                color: $theme-primary-color;
                position: relative;

                i {
                    display: block;
                    line-height: 20px;
                    position: absolute;
                    left: 0;
                    bottom: -30px;

                    img {
                        @media(max-width:991px) {
                            max-width: 45%;
                        }
                    }
                }
            }
        }

        p {
            max-width: 598px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
            color: $white;
            padding-left: 30px;
            position: relative;
            margin-bottom: 60px;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                width: 5px;
                height: 100%;
                content: "";
                background: $theme-primary-color;
                border-radius: 5px;

                @media(max-width:991px) {
                    display: none;
                }
            }

            @media(max-width:991px) {
                margin: 0 auto;
                margin-bottom: 20px;
                padding-left: 0;
            }
        }

        .slide-title {
            position: relative;
            max-width: 850px;

            @media(max-width:1199px) {
                max-width: 720px;
            }
        }

        .customer-review {
            display: flex;
            align-items: center;
            padding: 15px;
            padding-left: 20px;
            border-radius: 40px;
            border: 4px solid rgba(255, 255, 255, 0.05);
            background: rgba(255, 255, 255, 0.08);
            backdrop-filter: blur(2.5px);
            max-width: 275px;
            position: absolute;
            right: 0;
            bottom: 50px;

            @media(max-width:1199px) {
                bottom: 30px;
            }

            @media(max-width:991px) {
                position: relative;
                margin: 0 auto;
                bottom: 0;
                margin-bottom: 20px;
            }

            .customer-review-img {
                margin-right: 20px;
                display: flex;

                img {
                    margin: -10px;
                    border-radius: 50%;
                }
            }

            .customer-review-text {
                span {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    color: $white;
                    display: block;
                }

                ul {
                    display: flex;
                    margin-top: 3px;

                    li {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        color: $white;
                    }
                }
            }
        }
    }
}


/*1.2.1 static-hero-s2  */

.static-hero-s2 {

    @media(max-width:991px) {
        padding-top: 20px;
    }

    .static-hero-text {
        display: flex;
        padding-top: 100px;
        text-align: center;
        position: relative;

        @media (max-width: 991px) {
            text-align: center;
            padding-top: 0;
            display: block;
            margin-bottom: 0px;
        }

        .vector-shape {
            position: absolute;
            left: 200px;
            bottom: 100px;

            @media (max-width: 1199px) {
                display: none;
            }

        }

        h2 {
            font-size: 100px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: $dark-gray;
            margin-bottom: 30px;
            position: relative;
            display: inline-block;
            max-width: 1200px;
            margin: 0 auto;
            margin-bottom: 50px;

            @media (max-width: 1500px) {
                font-size: 100px;
                line-height: 100px;
            }

            @media (max-width: 1199px) {
                font-size: 70px;
                line-height: 70px;
                max-width: 715px;
            }

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }

            @media(max-width:767px) {
                font-size: 50px;
                line-height: 60px;
            }

            @media (max-width: 575px) {
                font-size: 44px;
                line-height: 44px;
                margin-top: 10px;
                margin-bottom: 0px;
            }

            @media(max-width:450px) {
                font-size: 30px;
                line-height: 35px;
            }


            b {
                position: relative;
                top: -30px;

                img {
                    border-radius: 84px 84px 0px 84px;

                    @media(max-width:767px) {
                        max-width: 120px;
                    }

                    @media(max-width:575px) {
                        max-width: 80px;
                        top: -20px;
                    }
                }
            }


            span {
                color: $theme-primary-color;

                i {
                    display: block;
                    line-height: 20px;

                    img {
                        @media(max-width:991px) {
                            max-width: 45%;
                        }
                    }
                }
            }

            small {
                position: absolute;
                right: -20px;
                top: -65px;

                @media(max-width:991px) {
                    right: -44px;
                    top: -20px;
                }

                @media(max-width:767px) {
                    display: none;
                }
            }
        }

    }

    .about-text {
        position: relative;
        z-index: 1;

        @media(max-width:991px) {
            padding-left: 0;
            padding-top: 30px;
            text-align: center;
            margin-bottom: 30px;
        }

        .about-icon-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            @media (max-width: 991px) {
                justify-content: center;
            }

            i {
                font-size: 70px;
                color: $theme-primary-color;
                margin-right: 20px;
            }

            h4 {
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 58px;
            }

            small {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 29px;
            }
        }

        span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
        }

        p {
            font-family: $heading-font;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 34px;
            color: $dark-gray;
            margin-top: 10px;
            margin-bottom: 20px;

            @media(max-width:1199px) {
                font-size: 20px;
                line-height: 30px;
            }

            @media(max-width:575px) {
                font-size: 19px;
                line-height: 25px;
            }

            b {
                color: $theme-primary-color;
            }
        }
    }

    .static-banner-sec {
        position: relative;
        z-index: 1;

        .left-shape {
            position: absolute;
            left: -30px;
            top: 0;

            @media(max-width:991px) {
                max-width: 100px;
            }

            @media(max-width:575px) {
                max-width: 80px;
            }
        }

        .radial-shape {
            position: absolute;
            right: -150px;
            bottom: -100px;
            z-index: -1;

            @media(max-width:1399px) {
                max-width: 200px;
                right: -80px;
                bottom: -50px;
            }

            @media(max-width:575px) {
                display: none;
            }
        }
    }


    .funfact-section {
        padding-top: 50px;
    }

}